import {
  Report,
  ReportTemplate,
  ReportType,
  utils,
} from "@socotec.io/socio-vue-components";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import {MISSIONS} from "@/utils/consts/missions";

const templateCodes = utils.report.templateCodes;
const reportStatuses = utils.report.statuses;

const state = {
  reportsCount: 0,
  isAnalysisConceptionSent: false,
  visitDate: Date.now(),
};

const getters = {
  /**
   * Get reports within Vuex Store
   * @returns {Report[]}
   */
  getReports() {
    return Report.all();
  },
  getReportsByTemplateId: () => (template_id) => {
    return Report.query().where("template", template_id).all();
  },
  getReportsCount: (state) => {
    return state.reportsCount;
  },
  getTemplateByName: () => (name) => {
    return ReportTemplate.query().where("name", name).first();
  },
  getTemplateByCode: () => (code) => {
    return ReportTemplate.query().where("code", code).first();
  },
  getTemplateByUuid: () => (uuid) => {
    return ReportTemplate.query().where("uuid", uuid).first();
  },
  getTemplates() {
    return ReportTemplate.all();
  },
  sortedTemplates() {
    return ReportTemplate.all().sort(
      (a, b) => a.orderingPosition - b.orderingPosition
    );
  },
  getTypes() {
    return ReportType.all();
  },
  sortedTypes() {
    return ReportType.all().sort(
      (a, b) => a.orderingPosition - b.orderingPosition
    );
  },
  getVisitDate: (state) => {
    return state.visitDate;
  },
  getReportTypesForGivenMission: () => (mission) => {
    if(mission === MISSIONS.KDAD){
      return  ReportType.query().where(reportType => reportType.name === 'Réalisation' || reportType.name === 'Conception').get();
    }
    return getters.sortedTypes();
  }
};

const actions = {
  /**
   * Fetch report list
   * @param [commit]
   * @param metadata
   * @param projectId
   * @returns {Promise<*>}
   */
  async fetchReportList({ commit }, { metadata }) {
    const request =
      new socioGrpcClient.report_generator.reports.ReportListRequest();
    const response =
      await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.list(
        request,
        {
          ...metadata,
        }
      );

    commit("UPDATE_REPORTS_COUNT", response.getCount());

    const responseObject = response.getResultsList();
    const reportsList = responseObject.map((element) => element.toObject());
    return await Report.insert({
      data: reportsList,
    });
  },

  /* Remove local report object from store
    It's helpfull for report Generation process:
    - We create a fake report in status "GENERATING",
    - When rapsosps-back send back the report UUID we fetch the real report and delete the fake report */
  async deleteLocalReport({ commit }, { reportUuid }) {
    const report = await Report.delete(reportUuid);
    await commit("UPDATE_REPORTS_COUNT", await Report.query().count());
    return report;
  },

  /* Remove local report object from store
    It's helpfull for report Generation process:
    - We create a fake report in status "GENERATING",
    - When rapsosps-back send back the report UUID we fetch the real report and delete the fake report */
  async insertLocalReport({ commit }, { data }) {
    const reportsData = await Report.insert({ data });
    await commit("UPDATE_REPORTS_COUNT", await Report.query().count());
    return reportsData.reports[0];
  },

  /**
   * Fetch report
   * @param [commit]
   * @param reportUuid Report uuid
   * @returns {Promise<any>}
   */
  async fetchReport({ commit }, { reportUuid }) {
    const request = new socioGrpcClient.report_generator.reports.Report();

    request.setUuid(reportUuid);

    const response =
      await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.retrieve(
        request,
        {}
      );
    const reportData = response.toObject();

    reportData.reportGenerationData = response
      .getReportGenerationData()
      .toJavaScript();

    const reportInsert = await Report.insert({
      data: reportData,
    });

    await commit("UPDATE_REPORTS_COUNT", await Report.query().count());

    return reportInsert.reports[0];
  },

  /**
   * Fetch last reports per contributors mails
   * @param [_]
   * @param usermanagementUuids {string[]}
   * @param templateId {string}
   * @param objectIds {string[]}
   * @returns {Promise<*>}
   */
  async fetchLastReportDatePerContributor(
    _,
    { usermanagementUuids, templateId, objectIds }
  ) {
    const request =
      new socioGrpcClient.report_generator.reports.GetLastReportDatePerContributorMessageRequest();

    request.setRecipientUsermanagementUuidsList(usermanagementUuids);
    request.setTemplate(templateId);
    request.setObjectIdsList(objectIds);

    const response =
      await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.getLastReportDatePerContributor(
        request,
        {}
      );

    return response.toObject().reportsList;
  },

  async fetchLastCRDate(_, { projectId }) {
    const request =
      new socioGrpcClient.report_generator.reports.LastCRSPSRequest();
    request.setObjectId(projectId);
    const response =
      await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.fetchLastSPSCR(
        request,
        {}
      );
    return response.toObject().resultsList;
  },

  /**
   * Fetch version attr from latest report by template Id
   * @param [_]
   * @param templateId {string}
   * @param objectId {string}
   * @returns {Promise<*>}
   */
  async fetchLastReportVersionByTemplateId(_, { objectId, templateId }) {
    const request =
      new socioGrpcClient.report_generator.reports.ReportLastVersionRequest();

    request.setObjectId(objectId);
    request.setTemplateId(templateId);

    const response =
      await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.reportLastVersion(
        request,
        {}
      );

    return response.toObject().version;
  },

  /**
   * Fetch report generation data from latest (sent) report by template Id
   * @param [_]
   * @param templateId {string}
   * @param objectId {string}
   * @returns {Promise<*>}
   */
  async fetchLastReportData(_, { objectId, templateId }) {
    const request =
      new socioGrpcClient.report_generator.reports.ReportDataRequest();

    request.setObjectId(objectId);
    request.setTemplateId(templateId);

    const response =
      await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.lastSentReportData(
        request,
        {}
      );

    const reportData = response.toObject();

    reportData.reportGenerationData = response
      .getReportGenerationData()
      .toJavaScript();

    return reportData;
  },

  async retrieveLastReportByTemplate(_, { operationUuid, templateCode, metadata}) {
    const request =
      new socioGrpcClient.report_generator.reports.ReportGetLastReportForObjectIdRequest();

      request.setObjectId(operationUuid);
      request.setTemplateCode(templateCode);

    const response =
      await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.getLastFullReportForObjectId(
        request,
        metadata
      );
      
    return response.toObject();
  },

  /**
   * Fetch report template list
   * @param [_]
   * @param metadata
   * @returns {Promise<reportTemplate[ReportTemplate[]]>}
   */
  async fetchReportTemplateList(_, metadata) {
    const request =
      new socioGrpcClient.report_generator.reports.ReportTemplateListRequest();
    const response =
      await socioGrpcClient.report_generator.reports.ReportTemplateControllerPromiseClient.list(
        request,
        metadata
      );

    return await ReportTemplate.insert({
      data: response.toObject().resultsList,
    });
  },

  /**
   * Fetch report type list
   * @param [_]
   * @param metadata
   * @returns {Promise<*>}
   */
  async fetchReportTypeList(_, metadata) {
    const request =
      new socioGrpcClient.report_generator.reports.ReportTypeListRequest();
    const response =
      await socioGrpcClient.report_generator.reports.ReportTypeControllerPromiseClient.list(
        request,
        metadata
      );

    return await ReportType.insert({
      data: response.toObject().resultsList,
    });
  },

  /**
   * Update report status
   * @param [context]
   * @param report
   * @param status Report Status
   * @returns {Promise<void>}
   */
  async updateReportStatus({ commit }, { report, status }) {
    const request =
      new socioGrpcClient.report_generator.reports.ReportUpdateStatusRequest();
    request.setUuid(report.uuid);
    request.setStatus(status);
    const response =
      await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.updateStatus(
        request,
        {}
      );

    const { data } = response.toObject();

    await Report.update({
      where: report.uuid,
      data: data,
    });

    // If sent report code is Initial Conception Analysis
    // set bool isAnalysisConceptionSent to true, it will unlock functionnalities
    // like PGC or DIUO referencials creation (only for V2 project and KDAA, KDAB, KDAC missions)
    if (
      data.status === reportStatuses.sent &&
      ReportTemplate.query().where("uuid", data.template).first().code ===
        templateCodes.INITIAL_CONCEPTION_ANALYSIS
    ) {
      commit("SET_IS_INITIAL_CONCEPTION_ANALYSIS_SENT");
    }
  },

  async checkIfAnalysisConceptionIsDiffused({ commit, dispatch, rootGetters }) {
    // On V2 projects and only for KDAA, KDAB, KDAC missions,
    // check if Initial Conception Analysis is sent.
    // If not some functionalities could be disabled
    // (for example PGC or DIUO referencials creation)

    if (
      !rootGetters["operation/isCurrentOperationKDAABC"] ||
      rootGetters["operation/isOperationImportedFromV1"]
    ) {
      return;
    }

    const metadata = {
      pagination: JSON.stringify({
        page_size: 100,
        page: 1,
      }),
      filters: JSON.stringify({
        status: reportStatuses.sent,
        templateCode: templateCodes.INITIAL_CONCEPTION_ANALYSIS,
        objectIds: [rootGetters["operation/getOperationUuid"]],
      }),
    };

    try {
      const { reports } = await dispatch("fetchReportList", { metadata });
      if (reports && reports.length > 0) {
        commit("SET_IS_INITIAL_CONCEPTION_ANALYSIS_SENT");
      }
    } catch (e) {
      console.log(e);
    }
  },

  /**
   * Delete a report
   * @param [context]
   * @param report
   * @returns {Promise<void>}
   */
  async deleteReport(context, report) {
    const request =
      new socioGrpcClient.report_generator.reports.ReportDestroyRequest();
    request.setUuid(report.uuid);

    await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.destroy(
      request,
      {}
    );
    await Report.delete(report.uuid);
  },
  async generateDraftReport(context, reportData) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.report_generator.reports.ReportCreateMessageRequest,
      reportData
    );
    try {
      const stream =
        await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.createDraft(
          request,
          {}
        );

      return stream;
    } catch (err) {
      console.error(err);
    }
  },
  setVisitDate({ commit }, date) {
    commit("SET_VISIT_DATE", date);
  },
  /**
   * Fetch version attr from latest report by template Id
   * @param [_]
   * @param templateId {string}
   * @param objectId {string}
   * @returns {Promise<*>}
   */
  async getLastFullReportForObjectId(_, { operationUuid, templateCode }) {
      const request =
    new socioGrpcClient.report_generator.reports.ReportGetLastReportForObjectIdRequest();
    request.setObjectId(operationUuid);
    request.setTemplateCode(templateCode);

    const response =
      await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.getLastFullReportForObjectId(
        request,
        {}
      );

    const reportData = response.toObject();

    reportData.reportGenerationData = response
    .getReportGenerationData()
    .toJavaScript();

  return reportData;
  },

  /**
   * Send preview report
   * @param [context]
   * @param report
   * @param attachements
   * @returns {Promise<void>}
   */
  async sendPreviewReport(_, { uuid, attachments, template, body, subject }) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.report_generator.reports.ReportSendPreviewMailRequest,
      {
        "uuid": uuid,
        "template": template,
        "attachmentsList": attachments,
        "body": body,
        "subject": subject
      }
    );
    const response =
      await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.sendPreviewMail(
        request,
        {}
      );
    const data = response.toObject();
    return data
  },

  async sendReportMail(_,form) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.report_generator.reports.ReportSendMailToRecipientsRequest,
      {
        ...form,
        "recipientsListList": form.recipientsList,
        "recipientsListCopyList": form.recipientsListCopy,
        "recipientsListCopyCacheList": form.recipientsListCopyCache,
        "attachmentsList": form.attachments.map((file) => file.fileUrl),
      }
    );
    const response =
      await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.sendMailToRecipients(
        request,
        {}
      );
    const data = response.toObject();
    return data
  },

  async setReportStatus(_, { report, status }) {
    await Report.update({
      where: report.uuid,
      data: {status},
    });
  }
};

const mutations = {
  UPDATE_REPORTS_COUNT: (state, newTotal) => {
    state.reportsCount = newTotal;
  },
  SET_IS_INITIAL_CONCEPTION_ANALYSIS_SENT: (state) => {
    state.isAnalysisConceptionSent = true;
  },
  SET_VISIT_DATE: (state, date) => {
    state.visitDate = date;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
