import { ref } from "vue";
import store from "@/store";

export function useMainNavigations(i18n) {
  const messages = {
    en: {
      DASHBOARD: "DASHBOARD",
      CASES: "CASES",
      OPERATIONS: "OPERATIONS",
      INTRANET: "INTRANET",
      REFERENCIALS: "REFERENCIALS",
      TECHNICAL_DOCUMENTATION: "TECHNICAL DOC.",
    },
    fr: {
      DASHBOARD: "PILOTAGE",
      CASES: "AFFAIRES",
      OPERATIONS: "OPERATIONS",
      INTRANET: "INTRANET",
      REFERENCIALS: "REFERENTIELS",
      TECHNICAL_DOCUMENTATION: "DOC. TECHNIQUE",
    },
  };

  i18n.mergeLocaleMessage("fr", messages.fr);
  i18n.mergeLocaleMessage("en", messages.en);

  const navItems = ref([
    {
      routeName: "dashboard",
      textLabel: i18n.t("DASHBOARD"),
      displayLink: store.getters["user/isTester"],
    },
    {
      routeName: "casesList",
      textLabel: i18n.t("CASES"),
      displayLink: true,
    },
    {
      routeName: "operationsList",
      textLabel: i18n.t("OPERATIONS"),
      displayLink: true,
    },
    {
      routeName: "referencials",
      textLabel: i18n.t("REFERENCIALS"),
      displayLink: store.getters["user/isTechnicalDirection"],
    },
    {
      routeName: "technical-document",
      textLabel: i18n.t("TECHNICAL_DOCUMENTATION"),
      params: { type: "template_sentence" },
      displayLink: store.getters["user/isTechnicalDirection"],
    },
    {
      url: "https://intranet.socotec.com/",
      textLabel: i18n.t("INTRANET"),
      displayLink: true,
    },
  ]);

  return {
    navItems,
  };
}
